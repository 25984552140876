import { render, staticRenderFns } from "./scroll.vue?vue&type=template&id=80c5b34a&scoped=true&"
import script from "./scroll.vue?vue&type=script&lang=js&"
export * from "./scroll.vue?vue&type=script&lang=js&"
import style0 from "./scroll.vue?vue&type=style&index=0&id=80c5b34a&prod&lang=less&scoped=true&"
import style1 from "./scroll.vue?vue&type=style&index=1&id=80c5b34a&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "80c5b34a",
  null
  
)

export default component.exports